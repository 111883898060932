<div *ngIf="sensor | async; else indicator">

    <h1>{{(sensor | async)?.name }}</h1>

    <div>
        <table mat-table fixedLayout="true"  [dataSource]="(sensor | async)?.values">
            <ng-container matColumnDef="datetime">
                <th mat-header-cell *matHeaderCellDef>Datum / Uhrzeit</th>
                <td mat-cell *matCellDef="let element">{{element.dateTimeFormatted}}</td>
            </ng-container>

            <ng-container matColumnDef="temperature">
                <th mat-header-cell *matHeaderCellDef>Temperatur</th>
                <td mat-cell *matCellDef="let element">{{element.temperature.value | number:'1.2-2' }}
                    {{element.temperature.unitOfMeasurement}}</td>
            </ng-container>

            <ng-container matColumnDef="relativeHumidity">
                <th mat-header-cell *matHeaderCellDef>Luftfeuchtigkeit</th>
                <td mat-cell *matCellDef="let element">{{element.relativeHumidity.value | number:'1.2-2' }}
                    {{element.relativeHumidity.unitOfMeasurement}}</td>
            </ng-container>

            <ng-container matColumnDef="pressure">
                <th mat-header-cell *matHeaderCellDef>Luftdruck</th>
                <td mat-cell *matCellDef="let element">{{element.pressure.value | number:'1.2-2' }}
                    {{element.pressure.unitOfMeasurement}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>

<ng-template #indicator>
    <mat-spinner #indicator></mat-spinner>
</ng-template>