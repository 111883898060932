import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {

    return new Promise<boolean>((resolve, reject) => {
      Auth.currentAuthenticatedUser().then(value => {
        resolve(true);
      }).catch(value => {
        console.log("Route: " + route.pathFromRoot
          .map(v => v.url.map(segment => segment.toString()).join('/'))
          .join('/'));
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Cognito,
          customState: route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/')
        })
        resolve(false);
      });
    });

  }

}