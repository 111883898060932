import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component'
import { 
  AuthGuardService as AuthGuard 
} from './auth-guard.service';
import { SensorsComponent } from './sensors/sensors.component';
import { SensorDetailsComponent } from './sensor-details/sensor-details.component';
import { SensorValuesComponent } from './sensor-values/sensor-values.component';

const routes: Routes = [
  { path: 'sensors', component: SensorsComponent, canActivate: [AuthGuard] },
  { path: 'sensors/:id', component: SensorValuesComponent, canActivate: [AuthGuard] },
  { path: 'sensors/:id/:name', component: SensorDetailsComponent, canActivate: [AuthGuard] },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
