import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './home/home.component';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import Amplify from '@aws-amplify/core';
import { APP_INITIALIZER } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { LoginComponent } from './login/login.component';
import { SensorsComponent } from './sensors/sensors.component';
import { SensorDetailsComponent } from './sensor-details/sensor-details.component';
import { SensorValuesComponent } from './sensor-values/sensor-values.component';

// const appInitializerFn =  (appConfig: AppConfigService) => {
//   return () => {
//     const loadedAppConfig = appConfig.loadAppConfig()
//     loadedAppConfig.then(() => {
//       Amplify.configure({
//         Auth: {
//           region: appConfig.getConfig().region,
//           userPoolId: appConfig.getConfig().userPoolId,
//           userPoolWebClientId: appConfig.getConfig().userPoolWebClientId,
//           cookieStorage: {
//             path: "/",
//             expires: "",
//             domain: window.location.hostname,
//             secure: appConfig.getConfig().secure,
//           },
//           oauth: {
//             domain: appConfig.getConfig().domain,
//             scope: ["phone","email","profile","openid","aws.cognito.signin.user.admin"],
//             redirectSignIn: appConfig.getConfig().redirectSignIn,
//             redirectSignOut: appConfig.getConfig().redirectSignOut,
//             responseType: "code",
//           }
//         }
//       });

//     });
//     return loadedAppConfig;
//   };
// };

// Amplify.configure({
//   Auth: {
//     region: 'eu-central-1',
//     userPoolId: "eu-central-1_iq6Uf8FMT",
//     userPoolWebClientId: "1k4ucq8kadjkqreg56tnmckc5g",
//     cookieStorage: {
//       path: "/",
//       expires: 30,
//       domain: window.location.hostname,
//       secure: false
//     },
//     oauth: {
//       domain: "iot-jumi.auth.eu-central-1.amazoncognito.com",
//       scope: ["phone","email","profile","openid","aws.cognito.signin.user.admin"],
//       redirectSignIn: 'http://localhost:4200',
//       redirectSignOut: 'http://localhost:4200',
//       responseType: "code",
//     }
//   }
// });


Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: "eu-central-1_iq6Uf8FMT",
    userPoolWebClientId: "1k4ucq8kadjkqreg56tnmckc5g",
    cookieStorage: {
      path: "/",
      expires: 30,
      domain: window.location.hostname,
      secure: true
    },
    oauth: {
      domain: "iot-jumi.auth.eu-central-1.amazoncognito.com",
      scope: ["phone","email","profile","openid","aws.cognito.signin.user.admin"],
      redirectSignIn: "https://iot.aws.jumi.dev",
      redirectSignOut: "https://iot.aws.jumi.dev",
      responseType: "code",
    },
  }
});


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SensorsComponent,
    SensorDetailsComponent,
    SensorValuesComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    // AppConfigService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFn,
    //   multi: true,
    //   deps: [AppConfigService]
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
