<div *ngIf="sensor | async; else indicator">

    <h1>{{(sensor | async)?.name }}: {{(sensor | async)?.sensorTitle }}</h1>

    <div>
        <table mat-table fixedLayout="true"  [dataSource]="(sensor | async)?.values">
            <ng-container matColumnDef="datetime">
                <th mat-header-cell *matHeaderCellDef>Datum / Uhrzeit</th>
                <td mat-cell *matCellDef="let element">{{element.dateTimeFormatted}}</td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Wert</th>
                <td mat-cell *matCellDef="let element">{{element.measuredValue | number:'1.2-2' }}
                    {{element.unitOfMeasurement}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>

<ng-template #indicator>
    <mat-spinner #indicator></mat-spinner>
</ng-template>