import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'IotApp';

  username = ''
  firstname = ''
  lastname = ''

  constructor(private router: Router) {

    Auth.currentAuthenticatedUser().then(value => {
      const payload =  value.signInUserSession.idToken.payload;
      this.username = payload.preferred_username ? payload.preferred_username : payload['cognito:username'];
      this.firstname = value.signInUserSession.idToken.payload.given_name;
      this.lastname = value.signInUserSession.idToken.payload.family_name;

      // console.log(JSON.stringify(value, null, 2))
    }).catch(value => {
    })

    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("event: " + JSON.stringify(event));
      console.log("event data: " + JSON.stringify(data));
      switch (event) {
        case "customOAuthState":
          this.router.navigateByUrl(data, {replaceUrl: true,});
      }
    });

  }

  signIn() {
    console.log('sign in')
    Auth.federatedSignIn();
  }

  signOut() {
    console.log('sign out')
    Auth.signOut();
  }

  // componentDidMount() {
  //   Hub.listen("auth", ({ payload: { event, data } }) => {
  //     console.log("event: " + JSON.stringify(event))
  //   });

  // }

}
