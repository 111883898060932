import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { SensorService } from '../core/http/api/sensor.service';
import { SensorValuesResponse } from '../core/http/model/sensorValuesResponse';
import { Auth } from '@aws-amplify/auth';

@Component({
  selector: 'app-sensor-values',
  templateUrl: './sensor-values.component.html',
  styleUrls: ['./sensor-values.component.css']
})
export class SensorValuesComponent implements OnInit {

  constructor(private route: ActivatedRoute, private sensorService: SensorService) { }

  id: string;
  sensor: Observable<SensorValuesResponse>;
  displayedColumns: string[] = ['datetime', 'temperature', 'relativeHumidity', 'pressure'];


  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      console.log("ID: " + this.id);
    });
    this.sensorService.configuration.apiKeys = {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    };
    
    this.sensor = this.sensorService.listSensorValues(this.id).pipe(share());
    ;
  };

}
