<h1>Home</h1>

<div>
    <table>
        <tr *ngFor="let smartplug of smartplugList | async; let i = index">
            <td>{{smartplug.name}}</td>
            <td>{{smartplug.status}}</td>
            <td><button (click)="sendCommand(smartplug.id, 'on')">on</button></td>
            <td><button (click)="sendCommand(smartplug.id, 'off')">off</button></td>
        </tr>
    </table>
</div>

<!--<div>
    <table mat-table [dataSource]="smartplugList | async" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>-->