/**
 * IotApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2020-12-08T18:25:19Z
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SensorDetailValueResponse { 
    dateTime?: string;
    unitOfMeasurement?: string;
    dateTimeFormatted?: string;
    measuredValue?: number;
    timestamp?: number;
}

