import { Component, OnInit } from '@angular/core';
import { SensorService } from '../core/http/api/sensor.service';
import { Auth } from '@aws-amplify/auth';
import { Observable } from 'rxjs';
import { SensorResponse } from '../core/http/model/sensorResponse';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.css']
})
export class SensorsComponent implements OnInit {

  constructor(private sensorService: SensorService) { }
  sensorList: Observable<SensorResponse[]>;
  displayedColumns: string[] = ['name'];


  async ngOnInit(): Promise<void> {
    this.sensorService.configuration.apiKeys = {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    };
    this.sensorList = this.sensorService.listSensors();
  };

}
