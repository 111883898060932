import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SensorService } from '../core/http/api/sensor.service';
import { Auth } from '@aws-amplify/auth';
import { share } from 'rxjs/operators';
import { SensorValuesDetailResponse } from '../core/http/model/sensorValuesDetailResponse';

@Component({
  selector: 'app-sensor-details',
  templateUrl: './sensor-details.component.html',
  styleUrls: ['./sensor-details.component.css']
})
export class SensorDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private sensorService: SensorService) { }

  id: string;
  name: string;

  sensor: Observable<SensorValuesDetailResponse>;
  displayedColumns: string[] = ['datetime', 'value'];


  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.name = params.get('name');

      console.log("ID: " + this.id);
      console.log("name: " + this.name);

    });
    this.sensorService.configuration.apiKeys = {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    };
    
    this.sensor = this.sensorService.listSensorValuesDetail(this.name, this.id).pipe(share());
    ;
  };


}
