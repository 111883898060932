import { Component, OnInit } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Observable } from 'rxjs';
import { SmartplugService } from '../core/http';
import { SmartPlugResponse } from '../core/http/model/smartPlugResponse';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  username = ''
  firstname = ''
  lastname = ''

  displayedColumns: string[] = ['name'];


  smartplugList: Observable<SmartPlugResponse[]>;

  constructor(private smartplugService: SmartplugService) {

    Auth.currentAuthenticatedUser().then(value => {
      this.username = value.signInUserSession.idToken.payload.preferred_username
      this.firstname = value.signInUserSession.idToken.payload.given_name
      this.lastname = value.signInUserSession.idToken.payload.family_name

      console.log(JSON.stringify(value, null, 2))
    }).catch(value => {
      
    })

  }

  async ngOnInit(): Promise<void> {
    this.smartplugService.configuration.apiKeys = {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    };
    this.smartplugList = this.smartplugService.listSmartPlugs();
  }

  async sendCommand(id: string, command: string) {
    this.smartplugService.configuration.apiKeys = {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    };
    console.log(id)
    await this.smartplugService.updateSmartPlug(id, {
      command,
    }).toPromise().then(value => {
      console.log(value)
    }).catch(value => {
      console.log(value)
    });

    this.smartplugList = this.smartplugService.listSmartPlugs();

  }

}
