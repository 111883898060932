<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <mat-nav-list>
      <a mat-list-item (click)="sidenav.toggle()" routerLink="/" >
        <mat-icon class="icon">home</mat-icon>
        <span class="label">Home</span>
      </a>
      <a mat-list-item (click)="sidenav.toggle()" routerLink="/sensors">
        <mat-icon class="icon">show_chart</mat-icon>
        <span class="label">Sensors</span>
      </a>
      <a *ngIf="!username" mat-list-item type="button">
        <mat-icon class="icon">input</mat-icon>
        <span class="label" (click)="signIn()">Login</span>
      </a>
      <a *ngIf="username" mat-list-item type="button">
        <mat-icon class="icon">input</mat-icon>
        <span class="label" (click)="signOut()">Logout</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
		<mat-toolbar color="primary" class="position-fixed">
      <div fxHide.gt-xs>
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div>
        <a routerLink="/">
          IoT App
        </a>
      </div>
      <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs>
        <ul fxLayout fxLayoutGap="20px" class="navigation-items">
          <li>
            <a routerLink="/">
              <mat-icon class="icon">home</mat-icon>
              <span class="label">Home</span>
            </a>
          </li>
          <li>
            <a routerLink="/sensors">
              <mat-icon class="icon">show_chart</mat-icon>
              <span class="label">Sensors</span>
            </a>
          </li>
          <li *ngIf="!username" >
            <a>
              <mat-icon class="icon">input</mat-icon>
              <span class="label" (click)="signIn()">Login</span>
            </a>
          </li>
          <li *ngIf="username" >
            <a type="button">
              <mat-icon class="icon">input</mat-icon>
              <span class="label" (click)="signOut()">Logout ( {{username}} )</span>
            </a>
          </li>
        </ul>
      </div>
    </mat-toolbar>
    <main class="main">
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>