<h1>Sensors</h1>

<div>
    <mat-card class="example-card"  *ngFor="let sensor of sensorList | async; let i = index">
        <mat-card-header>
          <div mat-card-avatar><mat-icon class="icon">contactless</mat-icon></div>
          <mat-card-title>{{sensor.name}}</mat-card-title>
        </mat-card-header>
        <mat-card-actions>
          <button mat-button [routerLink]="['/sensors', sensor.id]">Übersicht</button>
          <button mat-button *ngFor="let element of sensor.sensors" [routerLink]="['/sensors', sensor.id, element.name]">{{element.title}}</button>
        </mat-card-actions>
      </mat-card>
</div>